<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 搜索 -->
    <div class="search">
      <el-button type="primary" round class="in2" @click="setPhone"
        >设置联系电话</el-button
      >
      <span class="span1">内容关键词</span>
      <el-input
        class="in1"
        v-model="input"
        placeholder="请输入内容"
        @change="inputOne"
      ></el-input>
      <img
        src="../../assets/images/query.png"
        alt=""
        @click="searchA"
        style="width: 49px"
      />
    </div>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="id"
          label="条目编号"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          width="200"
        ></el-table-column>
        <el-table-column prop="summary" label="内容摘要"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="updateDate" label="修改时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleSearch(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 下边 -->
    <div class="bottom">
      <div class="bottom-left">
        <div style="margin-left: 30px; margin-top: 15px">
          <span style="font-size: 15px; color: #606266; margin-top: 20px"
            >共计{{ totalCount }}条数据</span
          >
        </div>
        <div>
          <el-button
            round
            class="top-btn"
            @click="addList"
            style="padding: 8px 22px; margin-right: 3%; margin-top: 15px"
            >增加</el-button
          >
        </div>
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
        <div class="buttons">
          <el-button>数据分析</el-button>
          <el-button>数据导出</el-button>
        </div>
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
      <el-form :model="formInline" class="demo-form-inline" label-width="70px">
        <el-form-item label="标题">
          <el-input
            v-model="formInline.title"
            placeholder=""
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="formInline.sort"
            placeholder=""
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容摘要">
          <el-input
            v-model="formInline.summary"
            placeholder="输入内容摘要"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="正文"> </el-form-item>
        <el-form-item label="" prop="text">
          <wangeditor
            @getEditor="getEditor"
            v-model="formInline.text"
            ref="Fueditor"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="quitAddList">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置联系电话 -->
    <el-dialog title="提示" :visible.sync="dialogVisiblePhone" width="45%">
      联系电话:<el-input
        v-model="inputPhone"
        placeholder="请输入内容"
        style="width: 62%; margin-left: 20px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePhone = false">取 消</el-button>
        <el-button type="primary" @click="queSetPhone">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import Wangeditor from "../call_center/Wangeditor.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      dialogVisible: false, //增加按钮的弹框
      dialogVisiblePhone: false,
      input: "",
      inputPhone: "",
      tableData: [],
      countNum: "",
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      formInline: {
        title: "",
        sort: "",
        summary: "",
        text: "",
      },
      textContent: {},
      biao: "",
      inputText: "",
    };
  },
  components: {
    Wangeditor,
  },
  watch: {
    textContent(val) {
      console.log("2222:", val);
      this.$nextTick(() => {
        console.log("监听数据修改了", val);
        this.$refs.Fueditor.editor.txt.html(val);
      });
      // console.log(this.$refs);
    },
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  created() {
    //帮助反馈列表
    this.getHelpList();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  methods: {
    inputOne(val) {
      this.inputText = val;
    },
    setPhone() {
      this.dialogVisiblePhone = true;
      this.inputPhone = "";
    },
    queSetPhone() {
      this.dialogVisiblePhone = false;
      apiShout.getupConLine({ phone: this.inputPhone }).then((res) => {
        console.log("设置联系电话：", res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "恭喜你,设置联系电话成功",
            type: "success",
          });
          this.inputPhone = "";
        }
      });
    },
    /**
     * 富文本编辑器
     */
    getEditor(msg) {
      console.log(msg);
      this.formInline.text = msg;
    },
    //上边的搜索
    searchA() {
      this.getHelpList();
      // this.input = "";
    },
    //修改
    handleEdit(index, row) {
      this.biao = row.id;
      //查询详情
      apiShout.getSearchHelp({ id: row.id }).then((res) => {
        console.log("查询详情：", res.data);
        if (res.data.code === 200) {
          this.dialogVisible = true;
          this.formInline = res.data.result;
          this.textContent = res.data.result.text;
        }
      });
    },
    //删除
    handleSearch(index, row) {
      this.$confirm("确定删除此条数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.getDeleteHelp({ id: row.id }).then((res) => {
            console.log("删除：", res.data);
            if (res.data.code == 200) {
              //刷新页面
              this.getHelpList();
              this.$message({
                message: "恭喜你,删除成功",
                type: "success",
              });
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //增加
    addList() {
      this.dialogVisible = true;
      this.formInline = {};
      this.$refs.Fueditor.editor.txt.clear();
    },
    //增加修改的确定
    quitAddList() {
      if (this.biao) {
        let obj = {
          id: this.biao,
          title: this.formInline.title,
          sort: this.formInline.sort,
          summary: this.formInline.summary,
          text: this.formInline.text,
        };
        //修改的确定
        this.dialogVisible = false;
        apiShout.getUpHelp(obj).then((aa) => {
          console.log("修改：", aa.data);
          if (aa.data.code == 200) {
            this.$message({
              message: "恭喜你,修改成功",
              type: "success",
            });
            //刷新页面
            this.getHelpList();
            //清空表单
            this.formInline = {};
            this.biao = "";
            //清空编辑器
            // this.reload();
            this.$refs.Fueditor.editor.txt.clear();
          }
        });
      } else {
        console.log("增加帮助与反馈");
        //增加的确定
        //关闭页面
        this.dialogVisible = false;
        apiShout.getAddHelp(this.formInline).then((res) => {
          console.log("增加帮助反馈：", res.data);
          if (res.data.code == 200) {
            this.$message({
              message: "恭喜你,增加成功",
              type: "success",
            });
            //刷新页面
            this.getHelpList();
            //清空表单
            this.formInline = {};
            //清空编辑器
            // this.reload();
            this.$refs.Fueditor.editor.txt.clear();
          }
        });
      }
    },
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
      this.$refs.Fueditor.editor.txt.clear();
      // this.reload();
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getHelpList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getHelpList();
    },
    //帮助反馈列表
    getHelpList() {
      apiShout
        .getHelp({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          text: this.inputText,
        })
        .then((res) => {
          console.log("帮助反馈列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.countNum = res.data.result.data.length;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // //增加的X
    // handleClose() {
    //   this.dialogVisible = false;
    //   //清空表单
    //   this.formInline = {};
    //   //清空编辑器
    //   // this.reload();
    //   this.$refs.Fueditor.editor.txt.clear();
    // },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-form-item__label {
  text-align: center;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
.span1 {
  font-size: 14px;
  font-weight: bold;
  color: #909399;
}
.search {
  height: 80px;
  margin: 0 40px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  .el-select {
    margin-right: 60px;
    margin-left: 10px;
  }
  .el-input {
    width: 120px;
    margin-left: 10px;
  }
  .in1 {
    margin-right: 10px;
  }
  .in2 {
    margin-right: 60px;
    background: #d78673;
    color: #ffffff;
  }
}
// table
.tables {
  margin-left: 30px;
  margin-right: 40px;
  .el-table::before {
    height: 0;
  }
  .el-table {
    // td,
    // th.is-leaf {
    //   border: none !important;
    // }
    /deep/.el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    tr th {
      font-size: 19px;
      color: #a8a8a8;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 17px;
      color: #a8a8a8;
    }
    .details {
      height: 30px;
      border-radius: 15px;
    }
  }
}
// 下边
.bottom-right {
  float: right;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}

.top-btn {
  background: #d78673;
  margin-left: 30px;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 10px;
}
.bottom-left {
  float: left;
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table th.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/.el-form-item__label {
  color: #909399;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>