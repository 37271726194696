<template>
  <div id="main"></div>
</template>

<script>
import E from "wangeditor";
export default {
  // props: ["textContent"],
  // inject:['reload'],
  // watch: {
  //   textContent(newdata) {
  //     this.editorContent = newdata;
  //   },
  //   deep: true,
  // },
  data() {
    return {
      editor: "",
      // editorContent: "",
    };
  },
  mounted() {
    // this.neweditor();
    this.editor = new E("#main");
    // var editor = new E(this.$refs.editorElem);
    this.editor.config.onchange = (html) => {
      // window.console.log(html);
      this.$emit("getEditor", html);
      this.editorContent = html;
      console.log(html, "1123123");
    };
    // 配置触发 onchange 的时间频率，默认为 200ms
    this.editor.config.onchangeTimeout = 500; // 修改为 500ms
    // 取消自动 focus
    this.editor.config.focus = false;
    this.editor.create();
    // editor.create();
    // editor.txt.html(this.textContent);
  },
};
</script>

<style>
</style>